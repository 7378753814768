import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ObjectID } from 'src/app/_services/_objectID';
import { Observable } from 'rxjs';
import { DirectorioService } from 'src/app/_services/directorio.service';

@Component({
  selector: 'app-directorio',
  templateUrl: './directorio.component.html',
  styleUrls: ['./directorio.component.css']
})
export class DirectorioComponent implements OnInit {

    directorio: any;
    directorios$: Observable<any>;
    
    constructor(private $router: Router, private $directorio: DirectorioService) { }

    ngOnInit() {
        this.directorio = undefined;
        this.directorios$ = this.$directorio.getDirectorios();
    }

    crearNuevoDirectorio() {
        this.$router.navigateByUrl(`/dashboard/(view:address-book/${ObjectID()}`);
    }

    seleccionarDirectorio(directorio: any) {
        this.directorio = {...directorio};
    }

}
