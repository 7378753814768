import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { IAgentMonitor } from "src/app/_interfaces/_all";
import { BehaviorSubject, of } from "rxjs";
import { debounceTime, delay, map, mapTo, mergeMap, tap } from "rxjs/operators";
import { INBOUND_SKILL } from "src/app/_interfaces/agente";
import { IAgentStateMonitor } from "src/app/_interfaces/monitor";
import { AgenteService } from "src/app/_services/agente.service";
import { EOutgoingCallType } from "../entities/Outgoing.enum";

@Component({
   selector: "app-manual",
   templateUrl: "./manual.component.html",
   styleUrls: ["./manual.component.css"],
})
export class ManualComponent implements OnInit {
   numero: FormControl;
   outboundCallRespose$ = new BehaviorSubject<{
      failed: boolean;
      inProgress: boolean;
      callid?: string;
      otherCallid?: string;
   }>({
      inProgress: false,
      failed: false,
   });

   @Input() agente: IAgentMonitor;
   @Output() callResponse = new EventEmitter<{
      callid?: string;
      otherCallid?: string;
   }>();

   @Output() colgar = new EventEmitter<Boolean>();

   get colasDisponibles() {
      return this.queuesNotOffline(this.agente.states).map((s) => s.queuename);
   }

   constructor(private $agente: AgenteService) {}

   ngOnInit() {
      this.numero = new FormControl("", Validators.required);
   }

   ejecutarLlamadaSaliente(
      { numero },
      cola?: string,
      outboundCallType = EOutgoingCallType.OUTBOUND_DIRECT
   ) {
      const queueMembership = !!cola
         ? cola
         : this.queuesNotOffline(this.agente.states).shift().queuename;

      const agentState = this.agente.states.find(
         (st) => st.queuename === queueMembership
      );
      const isAgentOutboundOrMixed =
         agentState.penalty < 0 || agentState.penalty > INBOUND_SKILL.MAX;

      const canalDeAgente = `Local/${this.agente.idagente}@agent${
         agentState.modalidad_agente.includes("RINGBACK") ? "Callback/n" : ""
      }`;

      of({ numero, queueMembership, agentState })
         .pipe(
            tap(() =>
               this.outboundCallRespose$.next({
                  failed: false,
                  inProgress: true,
                  callid: undefined,
               })
            ),
            debounceTime(600),
            mergeMap((evento) =>
               isAgentOutboundOrMixed
                  ? of(evento)
                  : this.$agente
                       .cambiarSkill(
                          this.agente.idagente,
                          evento.agentState.modalidad_agente,
                          -1,
                          evento.queueMembership
                       )
                       .pipe(
                          mapTo(evento),
                          delay(1200)
                          // Si falla??
                       )
            ),
            // formatear
            map(({ numero, queueMembership }) => ({
               numero,
               queueMembership,
               agentInterface: canalDeAgente,
               fuente: outboundCallType,
            })),
            mergeMap((request) => this.$agente.lanzarLlamada(request))
         )
         .subscribe(
            (commandResponse) => {
               this.outboundCallRespose$.next({
                  failed: false,
                  inProgress: false,
                  callid: `${Math.random()}`,
               });
               console.log(commandResponse);
            },
            (err) => {
               this.outboundCallRespose$.next({
                  failed: false,
                  inProgress: false,
                  callid: undefined,
               });
            }
         );
   }

   colgarLlamada() {
      this.colgar.emit(true);
   }

   queuesNotOffline(agentStates: IAgentStateMonitor[]) {
      return [...agentStates.filter((st) => st.status !== "OFFLINE")];
   }
}
