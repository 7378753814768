export interface IPeerMonitor {
   usuario: string;
   tipo: string | ETipoPeer;
   host: string;
   puerto: string;
   estado: string;
   estado_class: string;

   protocolo?: string;
   callerid?: string;
   comedia?: string;
   extra?: {
      tiempo: number;
      canal: string;
      app: string;
   };
   channels?: IChannel[];
}

export enum ETipoPeer {
   TRUNK,
   PEER,
}

export interface IChannel {
   Channel: string;
   Context: string;
   Exten: string;
   Priority: string;
   Stats: string;
   Application: string;
   Data: string;
   CallerID: string;
   Accountcode: string;
   peerAccount?: string;
   contactid?: string;
   Unidentified: string;
   Duration: string;
   Bridged: string;
   UniqueID: string;
   ["meta-info"]?: {};
}
export interface IQueueMonitor {
   nombre: string;
   callers: IQueueCaller[];
   stats: {
      holdtime: string;
      talktime: string;
      answered: string;
      abandon: string;
      serviceLevel: string;
      coverage: string;
   };
   agents: IAgentState[];
}

export interface IQueueStateMonitor {
   queue: string;
   state: IQueueState;
   agents: IAgentStateMonitor[];
}

export interface IQueueState {
   agents: {
      // <-- agent setups
      idagente: string;
      nombre: string;
      canXFER: boolean;
      canHangup: boolean;
      canPause: boolean;
      logged_on: string;
      modalidad_agente: string;
   }[];
   callers: {}[];
}
export interface IAgentStateMonitor {
   // <--- agent state
   agent: string;
   queuename: string;
   nombre?: string;
   penalty: number;
   modalidad_agente: string;
   status_idReason: string;
   status: string; // <--- ENUM please
   call_type: string;
   complete_agent: number;
   complete_caller: number;
   current_status_date: string;
   last_status_date: string; // <-- date when started the pause
   last_call_date: string;
   bound_channel_id: string;
   bound_channel: string;
   inbound: number;
   outbound: number;
   dialer: number;
   tmo_inbound: number;
   tmo_outbound: number;
   tmo_dialer: number;
   tmo_pause: number;
}

export interface IAgentStateChannel {
   channelid: string;
   name: string;
   callerid: {
      name: string;
      number: string;
   };
   state: string;
   account_code: string;
   date_creation: number;
   queue_name: string;
   queue_start_time?: number;
   contact_id?: string;
}
export interface IAgentState {
   fecha?: string;
   nombre?: string;
   idagente: string;
   estado: string;
   /**
    * @deprecated
    */
   channel?: IChannel;
   canPause?: boolean;
   canHangup?: boolean;
   canXFER?: boolean;
   canChangeSkill?: boolean;
   defaultPenalty: number; // <--- Penalidad por defecto

   /**
    * @deprecated
    */
   modoAgente: string;
   estado_idreason: string;
   states: IAgentStateMonitor[];
}

export enum EestadoAgentMonitor {
   OFFLINE,
   INBOUND_IDLE,
   OUTBOUND_IDLE,
   INBOUND_BUSY,
   INBOUND_BUSY_PAUSE,
   OUTBOUND_BUSY,
   PAUSE,
}

interface IQueueCaller {
   canal: string | IChannel;
   espera: string;
   posicion: string | number;
   prioridad: string;
}

export interface IQueueAlert {
   agente: string;
   cola: string;
   evento: string;
   fecha: string;
   tiempo_espera: string;
   uniqueid: string;
}

export interface IQueueStats {
   id: string; // <-- ipbxid
   fecha: string;
   queue: string;
   lastcall: string;
   inbound: number;
   outbound: number;
   abandon: number;
   abandon_exit: number;
   talkInbound: number;
   talkOutbound: number;
   inboundHoldtime: number;
   abandonHoldtime: number;
   SL: number;
   CI: number;
}
