import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { MD5, SHA1 } from "crypto-js";

import { Usuario } from "../_interfaces/usuario";
import { IDBResponse, EDBResponseType } from "../_interfaces/responses";

import { HeaderService } from "./header.service";
import { LocalStorageService } from "angular-2-local-storage";
import { switchMap, map, flatMap, catchError, tap } from "rxjs/operators";
import { IPBXID } from "src/_environments/environment";

@Injectable()
export class UsuarioService {
   usuario: Usuario;

   constructor(
      private $http: HttpClient,
      private $headers: HeaderService,
      private $localStorage: LocalStorageService
   ) {}

   /**
    * @returns Observable<{token: string, usuario: string}>
    */
   // <editor-fold defaultstate="collapsed" desc="getSesionUsuario(): Observable<{token: string, usuario: string}>">
   getSesionUsuario(): Observable<{ token: string; usuario: string }> {
      const sesion = this.$localStorage.get("kerberus_ipbx_token") as string;
      if (!!sesion) {
         const ipbxid =
            IPBXID ||
            window.location.href
               .substring(0, window.location.href.lastIndexOf(".kerberusipbx."))
               .replace("http://", "")
               .replace("https://", "");

         this.$headers.setHeader({ nombre: "Authorization", valor: sesion });
         this.$headers.setHeader({ nombre: "ipbxid", valor: ipbxid });

         return this.$http
            .get<{ token: string; usuario: string; serverOffsetTime: Number }>(
               this.$headers.getAPIurl() + "/usuario/auth",
               { headers: this.$headers.getHeaders() }
            )
            .pipe(
               tap(({ serverOffsetTime }) =>
                  this.$headers.setHeader({
                     nombre: "timeOffset",
                     valor: `${serverOffsetTime}`,
                  })
               ),
               map(({ usuario, token }) => ({ token, usuario }))
            );
      } else {
         return throwError({ ERR: "Sin logueo" });
      }
   }
   // </editor-fold>

   /**
    * @param id idUsuario
    * @returns Observable Usuario
    */
   getUsuario(id: string): Observable<Usuario> {
      return this.$http
         .get<Usuario[]>(this.$headers.getAPIurl() + "/usuario/" + id, {
            headers: this.$headers.getHeaders(),
         })
         .pipe(
            map((response) => response.shift()),
            flatMap((usuario) =>
               !!usuario
                  ? of(usuario)
                  : throwError({ code: 404, message: "Usuario no encontrado" })
            ),
            // Agregar el usuario para consultarlo despues
            tap((user) => (this.usuario = user))
         );
   }

   /**
    * @param usr: { usuario: string, password: string}
    * @returns Observable boolean
    */
   validarUsuario(usr: {
      usuario: string;
      password: string;
   }): Observable<{ token: string }> {
      // Encriptar el password
      return of(usr).pipe(
         map((user) =>
            Object.assign({}, user, {
               password: MD5(SHA1(usr.password).toString()).toString(),
            })
         ),
         switchMap((user) =>
            this.$http.post<IDBResponse>(
               this.$headers.getAPIurl() + "/usuario/auth",
               user
            )
         ),
         flatMap((response) => {
            if (response.tipo === EDBResponseType.AUTH_OK) {
               this.$localStorage.set(
                  "kerberus_ipbx_token",
                  response.data as string
               );
               return of({ token: response.data as string });
            } else {
               return undefined;
            }
         }),
         catchError((err) => throwError(err.error))
      );
   }

   /**
    * @returns Observable<string>
    */
   cerrarSesion(): Observable<string> {
      return this.$http
         .delete<IDBResponse>(this.$headers.getAPIurl() + "/usuario/auth", {
            headers: this.$headers.getHeaders(),
         })
         .pipe(
            map((res) => {
               if (res.tipo === EDBResponseType.OK) {
                  this.$localStorage.remove("kerberus_ipbx_token");
               }
               return res.data as string;
            })
         );
   }

   getUsuarios(): Observable<Usuario[]> {
      return this.$http.get<Usuario[]>(
         this.$headers.getAPIurl() + "/usuarios",
         {
            headers: this.$headers.getHeaders(),
         }
      );
   }

   actualizarUsuario(data: { usuario: Usuario; update: boolean }) {
      let http$: Observable<IDBResponse>;

      // Encriptar el password del usuario
      let usuario = Object.assign({}, data.usuario);
      if (usuario.password && usuario.password !== "") {
         usuario = Object.assign({}, usuario, {
            password: MD5(SHA1(usuario.password).toString()).toString(),
         });
      } else if (usuario.password && usuario.password === "") {
         delete usuario.password;
      }

      if (data.update === undefined) {
         http$ = this.$http.delete<IDBResponse>(
            this.$headers.getAPIurl() + "/usuario/" + usuario.usuario,
            { headers: this.$headers.getHeaders() }
         );
      } else if (data.update) {
         http$ = this.$http.post<IDBResponse>(
            this.$headers.getAPIurl() + "/usuario",
            usuario,
            {
               headers: this.$headers.getHeaders(),
            }
         );
      } else {
         http$ = this.$http.put<IDBResponse>(
            this.$headers.getAPIurl() + "/usuario",
            usuario,
            {
               headers: this.$headers.getHeaders(),
            }
         );
      }

      return http$;
   }
}
