export interface ICampanaSMS {
    id?: number;
    fecha?: string;
    fecha_ejecucion?: string;
    nombre: string;
    mensaje: string;
    estado?: boolean;
    rutaid: string;
}

export interface INumeroSMS {
    idnumero?: number;
    idcampana: number;
    numero: string;
    variable?: string | Array<Object>;
    estado: EEstadoNumeroSMS;
    fecha_mod?: string;
}

export interface ILogCampanaSMS {
    id?: number;
    fecha?: string;
    sms_result_id: string;
    status: string;
}

export interface ISMSRuta {
    id: String;
    fecha: Date;
    nombre: String;
    credito: number;
    valor_pactado: number;
}

export enum EEstadoNumeroSMS {
    EN_ESPERA   = 'EN_ESPERA',
    ENVIADO     = 'ENVIADO',
    FALLIDO     = 'FALLIDO',
    ENVIANDO    = 'ENVIANDO'
}
