<form novalidate autocomplete="off" [formGroup]="formulario">
    <input type="hidden" #frm_id formControlName="id">
    <input type="hidden" formControlName="fecha">
  <div style="padding-bottom: 10px">
      <input type="text" style="font-weight: 600; font-size: 16px" class="text-input" formControlName="titulo" placeholder="Título para éste formulario">
  </div>
  <div style="padding-bottom: 10px">
      <textarea class="text-input" formControlName="descripcion" placeholder="Descripción del formulario" style="font-size: 13px"></textarea>
  </div>    
  <div class="row" style="padding-bottom: 10px">
      <div class="col-sm-8">
          <auto-search [styleClass]="'text-input-dotted'" [style]="'12px'" [header]="'Colas disponibles'" [opciones]="queueList" formControlName="colas"></auto-search>
            <span class="text-muted" style="font-size: 11px; padding-left: 10px" *ngIf="formulario.controls.colas.value.length === 0">
                * Este formulario será visible para todos los agentes.
            </span>
            <small *ngIf="colasControl.errors" class="text-danger" style="font-weight: 600;">
               La lista de campañas es de máximo 30.<br>Actualmente tienes {{colasControl.errors.currentLenght}} de más.
            </small>
      </div>
      <div class="col-sm-4 text-right" style="padding-top: 5px">
          <div class="dropdown">
              <button type="button" class="btn btn-xs" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                  Agregar pregunta <i class="fa fa-caret-down"></i>
              </button>
              <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1" style="font-size: 13px">
                  <li><a href="javascript:void(0)" (click)="addPregunta('ABIERTA')"><i class="fa fa-text-width text-muted"></i> Respuesta abierta</a></li>
                  <li><a href="javascript:void(0)" (click)="addPregunta('NUMERO')"><i class="fa fa-hashtag text-muted"></i> Respuesta numerica</a></li>
                  <li><a href="javascript:void(0)" (click)="addPregunta()"><i class="fa fa-dot-circle-o text-muted"></i> Selección múltiple</a></li>
                  <li><a href="javascript:void(0)" (click)="addPregunta('FECHA')"><i class="fa fa-calendar-check-o text-muted"></i> Respuesta con fecha</a></li>
              </ul>
          </div>
      </div>
  </div>
  
  <div formArrayName="pregunta">
      <div class="panel" style="background-color: #f7f7f7; border: 1px solid #f2f2f2" 
      *ngFor="let p of formulario.get('pregunta')['controls']; let i = index">

          <div [formGroupName]="i" class="panel-body">
              <input type="hidden" formControlName="orden">
              <input type="hidden" formControlName="tipo" #tipoPregunta>

              <input formControlName="pregunta" type="text" class="text-input" placeholder="{{(i+1)}}. Pregunta..." style="margin-bottom: 10px; font-weight: 600">

              <div formArrayName="respuestas">
                  <div *ngFor="let r of p.controls.respuestas.controls; let j = index">
                      <div [formGroupName]="j" class="input-group input-group-sm" style="padding-bottom: 10px">
                          <span class="input-group-addon">
                              <i class="fa" [ngClass]="{'fa-circle-o': tipoPregunta.value === 'SELECCION', 'fa-text-width': tipoPregunta.value === 'ABIERTA', 'fa-calendar-check-o' : tipoPregunta.value === 'FECHA', 'fa-hashtag' : tipoPregunta.value === 'NUMERO'}"></i>
                          </span>
                          <input type="text" *ngIf="tipoPregunta.value === 'SELECCION' && r.value.tipo === 'SIMPLE'" formControlName="respuesta" class="form-control" aria-label="..." placeholder="Opción {{(j+1)}}">
                          <input type="text" *ngIf="tipoPregunta.value === 'SELECCION' && r.value.tipo === 'OTRO'" formControlName="respuesta" value="" readonly class="form-control" aria-label="..." placeholder="Otro...">
                          <input type="text" *ngIf="tipoPregunta.value === 'ABIERTA'" formControlName="respuesta" value="" readonly class="form-control" aria-label="..." placeholder="Texto de Usuario...">
                          <input type="text" *ngIf="tipoPregunta.value === 'NUMERO'" formControlName="respuesta" value="" readonly class="form-control" aria-label="..." placeholder="Texto numerico de Usuario ...">
                          <input type="text" *ngIf="tipoPregunta.value === 'FECHA'" formControlName="respuesta" value="" readonly class="form-control" aria-label="..." placeholder="Fecha a ser seleccionada">

                          <span class="input-group-btn" *ngIf="tipoPregunta.value === 'SELECCION'">
                              <button class="btn btn-default" type="button" (click)="delRespuesta(p.controls.respuestas, j)"><i class="fa fa-close"></i></button>
                          </span>
                      </div>
                  </div>
              </div>                        

              <div class="row">
                  <div class="col-xs-6">
                      <span class="btn-group">
                           <button class="btn btn-xs" title="Subir pregunta" type="button" [disabled]="true"><i class="fa fa-arrow-up"></i></button>
                           <button class="btn btn-xs" title="Bajar pregunta" type="button" [disabled]="true"><i class="fa fa-arrow-down"></i></button>
                       </span>
                      <button type="button" class="btn btn-xs" (click)="delPregunta(formulario.controls.pregunta, i)">
                          <i class="fa fa-trash"></i> Eliminar pregunta
                       </button>
                  </div>
                  <div class="col-xs-6 text-right">
                      <span *ngIf="tipoPregunta.value === 'SELECCION'">
                          <a href="javascript:void(0)" (click)="addRespuesta(p.controls.respuestas, 'SIMPLE')">
                              <i class="fa fa-check-circle-o"></i> Añadir Opción
                          </a>
                          <span *ngIf="!(p.controls.respuestas | otro)"> 
                              | <a href="javascript:void(0)" (click)="addRespuesta(p.controls.respuestas, 'OTRO')">
                                  <i class="fa fa-pencil"></i> Añadir "OTRO"
                              </a>
                          </span>
                      </span>
                  </div>


              </div>
          </div>
      </div>
  </div>
</form>