import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormasService } from 'src/app/_services/formas.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { of, Observable } from 'rxjs';
import { tap, debounceTime, flatMap, finalize, delay, map } from 'rxjs/operators';
import { ITipificacionLog } from 'src/app/_interfaces/_all';
import * as dayjs from 'dayjs';
import * as _ from 'lodash';

const dateInRange = (fecha: FormGroup): ValidationErrors | null => {
    const inicio = fecha.get('inicio').value;
    // Chequear si el rango en menor a 1 mes
    if (!!inicio) {
        const fechaInicio = dayjs(inicio);
        const fechaFin = !!fecha.get('fin').value ? dayjs(fecha.get('fin').value) : dayjs();
        if (fechaFin.diff(fechaInicio, 'month') > 0) {
            return { rango: true }
        }
        if (fechaInicio.diff(fechaFin, 'day') > 0) {
            return { mayor: true }
        }
    }
    return null;
}

@Component({
  selector: 'app-resultados-tipificacion',
  templateUrl: './resultados-tipificacion.component.html',
  styleUrls: ['./resultados-tipificacion.component.css']
})
export class ResultadosTipificacionComponent implements OnInit {

    inProgress = false;
    formConsulta: FormGroup;
    resultados$: Observable<ITipificacionLog[]>;

  constructor(
      private $router: Router,
      private $fb: FormBuilder,
      private $route: ActivatedRoute,
      private $tipificacion: FormasService
  ) { }

    ngOnInit() {
        this.resultados$ = of([]);
        this.formConsulta = this.$fb.group({
            inicio: ['', Validators.required],
            fin: ''
        }, { validators: dateInRange });
    }

    buscar(fecha: { inicio: string, fin?: string }) {
        const { idformulario } = this.$route.snapshot.params;
        this.resultados$ = of(true).pipe(
            tap(() => this.inProgress = true),
            debounceTime(400),
            flatMap(() => this.$tipificacion.buscar({ idformulario, fecha })),
            map(resultados => _.orderBy(resultados, 'fecha').reverse()),
            finalize(() => this.inProgress = false)
        );
    }

}
