import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   OnInit,
   Output,
   SimpleChange,
   SimpleChanges,
} from "@angular/core";
import {
   FormGroup,
   FormArray,
   FormBuilder,
   Validators,
   FormControl,
   ValidationErrors,
} from "@angular/forms";
import { IAgentState, IAgentStateChannel } from "../../_interfaces/monitor";
import {
   ITipificacionLog,
   IFormaPregunta,
   IForma,
} from "../../_interfaces/forma";
import * as _ from "lodash";

import { FormasService } from "../../_services/formas.service";
import { Observable, of, zip, EMPTY } from "rxjs";
import { SocketService } from "src/app/_services/socket.service";
import {
   map,
   tap,
   delay,
   finalize,
   debounceTime,
   switchMap,
   filter,
   defaultIfEmpty,
   mergeMap,
   mapTo,
} from "rxjs/operators";
import { ContactosService } from "src/app/_services/contactos.service";
import * as dayjs from "dayjs";

function isvalidNumber(control: FormControl): ValidationErrors | null {
   return /[^0-9-]/.test(control.value) ? { hasInvalidChars: true } : null;
}

function isValidDate(control: FormControl): ValidationErrors | null {
   return !dayjs(control.value.trim()).isValid() ? { invalidDate: true } : null;
}

interface ISimpleChanges extends SimpleChanges {
   formulario: IFormaChange;
   channelSnapshot: IChannelSnapshotChange;
}
interface IFormaChange extends SimpleChange {
   currentValue: IForma;
   previousValue: IForma;
}
interface IChannelSnapshotChange extends SimpleChange {
   currentValue: IAgentStateChannel;
   previousValue: IAgentStateChannel;
}
@Component({
   selector: "agente-tipificacion",
   templateUrl: "tipificacion.component.html",
})
export class TipificacionComponent implements OnInit, OnChanges {
   frm: FormGroup;
   isFormAsociated = false;
   frmTipificacion: FormGroup;
   channelInCall$: Observable<IAgentStateChannel>;
   titulo: { texto: string; subtitulo: string };
   actionInprogress = { saving: false, loading: false, error: false };

   @Input()
   formulario: IForma;

   @Input()
   agente: IAgentState;

   @Input()
   channel: IAgentStateChannel; // Variable que se pasa por lor args del control

   @Output()
   doneWithForm = new EventEmitter<string>();

   // Variable donde se alamcenará el canal.
   private channelSnapshot: IAgentStateChannel;

   get preguntasArray() {
      return this.frmTipificacion.get("preguntas") as FormArray;
   }

   constructor(
      private $forma: FormasService,
      private fb: FormBuilder,
      private $monitor: SocketService,
      private $contacto: ContactosService
   ) {}

   ngOnInit() {
      this.frm = this.fb.group({
         log: this.fb.array([]),
      });

      this.frmTipificacion = this.fb.group({
         callid: ["", Validators.required],
         fecha: ["", Validators.required],
         idautor: ["", Validators.required],
         autor: ["", Validators.required],
         numero: ["", Validators.required],
         cola: ["", Validators.required],
         idformulario: ["", Validators.required],
         preguntas: this.fb.array([]),
      });

      // monitor de eventos de agente y nueva llamada.
      this.channelInCall$ = this.$monitor.getAgentState$().pipe(
         debounceTime(1000),
         map((states) => {
            const agentState = states.find((s) => !!s.bound_channel_id);
            return !!agentState
               ? (JSON.parse(agentState.bound_channel) as IAgentStateChannel)
               : null;
         })
      );
   }

   ngOnChanges(cambios: ISimpleChanges) {
      if (!!cambios.formulario) {
         this.titulo = {
            texto: this.formulario.titulo,
            subtitulo: this.formulario.descripcion,
         };

         of(this.formulario)
            .pipe(
               tap(
                  () =>
                     (this.actionInprogress = {
                        error: false,
                        loading: true,
                        saving: false,
                     })
               ),
               switchMap((forma) =>
                  this.$forma.getPreguntasFormulario(forma.id)
               ),
               finalize(
                  () =>
                     (this.actionInprogress = {
                        error: false,
                        loading: false,
                        saving: false,
                     })
               )
            )
            .subscribe(
               (formularioPreguntas) => {
                  // Se hace una copia fiel del canal para conservar el estado.
                  this.channelSnapshot = !!this.channel
                     ? { ...this.channel }
                     : undefined;
                  this.isFormAsociated = !!this.channelSnapshot;
                  this.initFormTipificacion(formularioPreguntas);
               },
               (err) => console.error(err)
            );
      }
   }

   initFormTipificacion(preguntas: IFormaPregunta[]) {
      const validadoresRespuesta = (tipo: string) => {
         switch (tipo) {
            case "NUMERO":
               return [Validators.required, isvalidNumber];
            case "FECHA":
               return [Validators.required, isValidDate];
            default:
               return [Validators.required];
         }
      };

      const preguntasArray = this.preguntasArray;
      _.sortBy(preguntas, "orden").forEach((pregunta: IFormaPregunta) => {
         const control = this.fb.group({
            tipo: "",
            enunciado: "",
            respuestas: [],
            respuesta: ["", validadoresRespuesta(pregunta.tipo)],
         });
         control.patchValue({
            tipo: pregunta.tipo,
            enunciado: pregunta.pregunta,
            respuestas: pregunta.respuestas,
         });
         preguntasArray.push(control);
      });

      const currentAgentState = this.agente.states.find(
         (st) => st.bound_channel_id !== ""
      );

      this.frmTipificacion.patchValue({
         idautor: this.agente.idagente,
         autor: this.agente.nombre,
         idformulario: preguntas[0].idformulario,
         fecha: dayjs().format("YYYY-MM-DD HH:mm:ss"),
         numero: this.channelSnapshot?.callerid.number ?? `N/A`,
         cola: currentAgentState?.queuename ?? `N/A`,
         callid: !!this.channelSnapshot
            ? this.channelSnapshot.channelid
            : `no-call_${ObjectID()}`,
      });
   }

   /**
    * Función para asignar el canal
    * @param llamada
    */
   asignarLlamada() {
      // Hacer copia fiel del canal.
      this.channelSnapshot = !!this.channel ? { ...this.channel } : undefined;
      const currentAgentState = this.agente.states.find(
         (st) => st.bound_channel_id !== ""
      );

      if (!!this.channelSnapshot) {
         this.frmTipificacion.patchValue({
            callid: this.channelSnapshot.channelid,
            numero: this.channelSnapshot.callerid.number,
            cola: currentAgentState?.queuename ?? `N/A`,
            fecha: dayjs().format("YYYY-MM-DD HH:mm:ss"),
         });
         this.isFormAsociated = true;
      }
   }

   /**
    * @description Guardar el log en DB
    * @param formas: ITipificacionLog
    */
   guardarFormulario(formas: ITipificacionLog) {
      // Observable para obtener del formulario los campos necesarios.
      const formulario$ = of(formas).pipe(
         map((resultado) => {
            const { preguntas, ...resto } = resultado;
            const respuestas = preguntas.map((r) => ({
               enunciado: r.enunciado,
               respuesta: `${r.respuesta}`,
               tipo: r.tipo,
            }));
            return { ...resto, preguntas: respuestas } as ITipificacionLog;
         })
      );

      // Capturar el peerAccount
      const contactTipificado = (call: Observable<IAgentStateChannel>) =>
         call.pipe(
            filter((channel) => !!channel),
            map((channel) => channel.contact_id),
            mergeMap((id) => (!!id ? this.$contacto.getContacto(id) : EMPTY)),
            filter((contacto) => !!contacto),
            defaultIfEmpty({ _id: "N/A" })
         );

      of(true)
         .pipe(
            tap(
               () =>
                  (this.actionInprogress = {
                     error: false,
                     loading: false,
                     saving: true,
                  })
            ),
            debounceTime(400),
            mapTo(this.channelSnapshot),
            contactTipificado,
            mergeMap((contacto) => zip(of(contacto), formulario$)),
            // Formatear
            map(([contacto, log]) => ({
               ...log,
               contacto: contacto,
            })),
            // Guardar
            mergeMap((resultado) => this.$forma.guardarResultado(resultado)),
            tap(() => {
               $("#modal_tipitificacion_ok").modal();
               this.actionInprogress = {
                  error: false,
                  loading: false,
                  saving: false,
               };
            }),
            delay(3000),
            finalize(() => $("#modal_tipitificacion_ok").modal("hide"))
         )
         .subscribe(
            (_) => {
               this.channelSnapshot = undefined;
               this.regresar();
            },
            (err) => {
               this.actionInprogress = {
                  error: true,
                  loading: false,
                  saving: false,
               };
               console.warn(err);
            }
         );
   }

   regresar() {
      this.doneWithForm.emit("clear_please");
   }
}

export function ObjectID(): string {
   return Date.now().toString(16);
}
