import {
   Component,
   OnInit,
   Input,
   OnChanges,
   SimpleChanges,
   SimpleChange,
   Output,
   EventEmitter,
} from "@angular/core";
import { ICallStatus } from "src/app/_interfaces/callstatus";
import {
   ICampoDirectorio,
   ETipoCampoDirectorio,
} from "src/app/_interfaces/directorio";
import { Observable, from, zip, of, throwError } from "rxjs";
import {
   FormGroup,
   FormControl,
   Validators,
   FormBuilder,
   FormArray,
} from "@angular/forms";
import { ContactosService } from "src/app/_services/contactos.service";
import { IContacto } from "src/app/_interfaces/contacto";
import {
   map,
   filter,
   toArray,
   tap,
   reduce,
   flatMap,
   switchMap,
   first,
   debounceTime,
   concat,
   delay,
   retry,
   catchError,
   mergeMap,
} from "rxjs/operators";
import { DirectorioService } from "src/app/_services/directorio.service";
import { ObjectID } from "src/app/_services/_objectID";

interface ISimpleChanges extends SimpleChanges {
   callStatus: ISimpleChange;
}

interface ISimpleChange extends SimpleChange {
   currentValue: ICallStatus;
   previousValue: ICallStatus;
}

@Component({
   selector: "app-contacto",
   templateUrl: "./contacto.component.html",
   styleUrls: ["./contacto.component.css"],
})
export class ContactoComponent implements OnInit, OnChanges {
   isNewContact = true;
   queryContact = false;
   formContacto: FormGroup;
   busqueda: { index: string; telefono: string };
   inProgress: { progress: boolean; err?: boolean; msj?: boolean } = {
      progress: false,
   };

   @Input() callStatus: ICallStatus;
   @Input() contacto$: Observable<IContacto>;
   @Input() directorio$: Observable<{ iddb: string; numero: string }>;
   @Output() updateChannel = new EventEmitter<string>();

   get addressBook() {
      return this.formContacto.get("addressBook");
   }

   constructor(
      private $fb: FormBuilder,
      private $contacto: ContactosService,
      private $directorio: DirectorioService
   ) {}

   ngOnInit() {
      this.formContacto = this.$fb.group({
         _id: ["", Validators.required],
         iddb: ["", Validators.required],
         addressBook: this.$fb.array([]),
      });

      this.contacto$
         .pipe(
            tap(() => (this.queryContact = true)),
            // Buscar el contacto
            switchMap((contacto) =>
               zip(of(contacto), this.$contacto.getContacto(contacto._id))
            ),
            mergeMap(([contacto, response]) =>
               !!response
                  ? of([contacto, response]).pipe(
                       tap(() => (this.isNewContact = false))
                    )
                  : throwError("Not found")
            ),
            // establecer si la busqueda tuvo resultado.
            map(([queryContacto, contacto]) =>
               !!contacto ? contacto : queryContacto
            ),
            // Obtener los campos del directorio
            mergeMap((contacto) =>
               zip(of(contacto), this.camposDirectorio$(contacto.iddb))
            ),
            // Asignar el valor
            tap(([contacto, campos]) => this.initFrom(campos, contacto)),
            // Si falla reiniciar el observable
            catchError((err) => {
               this.queryContact = false;
               throw err;
            }),
            retry()
         )
         .subscribe(
            () => (this.queryContact = false),
            (err) => console.error(err)
         );

      // Observable para cargar el formulario para llenar.
      this.directorio$
         .pipe(
            tap(() => (this.queryContact = true)),
            // cargar los campos del directorio
            switchMap(({ iddb, numero }) =>
               this.camposDirectorio$(iddb).pipe(
                  // Intentar buscar el contacto por numero telefónico
                  concat(this.$contacto.buscarContactPorTelefono(iddb, numero)),
                  toArray(),
                  // cargar el formulario en blanco
                  tap(
                     ([campos, contacto]: [
                        ICampoDirectorio[],
                        IContacto[]
                     ]) => {
                        if (!contacto) {
                           this.isNewContact = true;
                           this.initFrom(campos, {
                              _id: ObjectID(),
                              iddb: iddb,
                           });
                        } else if (contacto.length === 1) {
                           // Solo hay un match
                           const _contacto = contacto.shift();
                           this.isNewContact = false;
                           this.initFrom(campos, _contacto);
                           this.updateChannel.emit(_contacto._id);
                        } else {
                           if (contacto.length > 1) {
                              // TODO: Mostrar varios contactos para poder cargarlo
                           }
                           this.isNewContact = true;
                           this.initFrom(campos, {
                              _id: ObjectID(),
                              iddb: iddb,
                           });
                        }
                     }
                  )
               )
            ),
            // delay necesario para garantizar la información del canal.
            delay(1000)
         )
         .subscribe(
            () => (this.queryContact = false),
            (err) => {
               console.error(err);
               this.queryContact = false;
            }
         );
   }

   ngOnChanges(cambios: ISimpleChanges) {
      const { callStatus } = cambios;
      if (!!callStatus) {
      }
   }

   initFrom(campos: ICampoDirectorio[], contacto?: IContacto) {
      from(campos)
         .pipe(
            filter((campo) => campo.visible),
            map((campo) => {
               const control = new FormControl("", Validators.required);
               // Propiedad editable
               if (!!contacto[campo.nombre] && !campo.editable) {
                  control.disable({ onlySelf: true });
               }
               // Establecimiento de valor
               if (!!contacto[campo.nombre]) {
                  control.setValue(contacto[campo.nombre]);
               }

               const controlGroup = this.$fb.group({
                  nombreCampo: campo.nombre,
                  tipoCampo: campo.tipo,
                  campo: control,
               });
               return controlGroup;
            }),
            toArray()
         )
         .subscribe((controles) => {
            this.formContacto = this.$fb.group({
               _id: ["", Validators.required],
               iddb: ["", Validators.required],
               addressBook: this.$fb.array(controles),
            });

            if (!!contacto) {
               this.formContacto.patchValue({
                  _id: contacto._id,
                  iddb: contacto.iddb,
               });
            }
         });
   }

   getControlInFormContacto$(nombre: string) {
      const arrayGroup = this.formContacto.get("addressBook") as FormArray;
      return from(arrayGroup.controls as FormGroup[]).pipe(
         filter((grupo) => !!grupo.get(nombre)),
         map((grupo) => grupo.get("campo")),
         first()
      );
   }

   guardarInformacion(frmData: {
      _id: string;
      iddb: string;
      addressBook: { nombreCampo: string; campo: string }[];
   }) {
      const { addressBook, ...id_iddb } = frmData;
      of(addressBook)
         .pipe(
            tap(() => (this.inProgress = { progress: true })),
            debounceTime(200),
            // Hacer stream de los campos
            flatMap((campos) => campos),
            filter((data) => !!data.campo),
            map((data) => ({ [data.nombreCampo]: data.campo })),
            // combinar en un solo objeto
            reduce((acc, curr) => ({ ...acc, ...curr })),
            // Agreagar campos de identificacion
            map((data) => ({ ...id_iddb, ...data })),
            flatMap((contacto) =>
               this.isNewContact
                  ? this.$contacto
                       .guardarContactos(
                          [contacto],
                          this.busqueda.index,
                          this.busqueda.telefono
                       )
                       .pipe(
                          tap(() => this.updateChannel.emit(contacto._id)),
                          // delay para garantizar la actualización del canal.
                          delay(1000)
                       )
                  : this.$contacto.guardarContacto(
                       contacto,
                       this.busqueda.index,
                       this.busqueda.telefono
                    )
            )
         )
         .subscribe(
            (response) => {
               this.isNewContact = false;
               this.inProgress = { progress: false, msj: true };
            },
            (err) => {
               console.error(err);
               this.inProgress = { progress: false, err: true };
            }
         );
   }

   limpiarFormulario(iddb: string) {
      // Crear de nuevo el formulario
      this.queryContact = true;
      this.updateChannel.emit("");
      this.camposDirectorio$(iddb)
         .pipe(
            tap((campos) => {
               this.isNewContact = true;
               this.initFrom(campos, { _id: ObjectID(), iddb: iddb });
            })
         )
         .subscribe(() => (this.queryContact = false));
   }

   private camposDirectorio$ = (iddb: string) =>
      of(iddb).pipe(
         filter((idDirectorio) => !!idDirectorio),
         switchMap((idDirectorio) =>
            this.$directorio.getDirectorio(idDirectorio)
         ),
         tap((directorio) => {
            const campoTel = directorio.campos.find(
               (c) => c.tipo === ETipoCampoDirectorio.TELEFONO
            );
            // util para determinar el nombre de la propiedad a buscar en la la vista
            this.busqueda = {
               index: directorio.primario,
               telefono: campoTel.nombre,
            };
         }),
         map((directorio) => directorio.campos)
      );
}
