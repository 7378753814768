import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";
import { IPBXComponent } from "./app.component";

// Root Components
import { LoginComponent } from "./login/login.component";
import { AdminComponent } from "./admin/admin.component";
import { AgenteComponent } from "./agente/agente.component";
import { AgenteConsoleModule } from "./agente/agente.module";

import { AdminModule } from "./admin/admin.module";
import { ReportesModule } from "./admin/reportes/reportes.module";
import { IvrModule } from "./admin/ivr/ivr.module";

// Services
import { HeaderService } from "./_services/header.service";
import { UsuarioService } from "./_services/usuario.service";
import { AgentResolver } from "./_services/resolvers";
import { FormasService } from "./_services/formas.service";

import { LocalStorageModule } from "angular-2-local-storage";

import * as moment from "moment";
import * as dayjs from "dayjs";
import "moment/locale/es";
import "dayjs/locale/es";

import { WaitLoaderComponent } from "./_extra/wait/wait.component";
import { CallbackComponent } from "./admin/callback/callback.component";
import { TipificacionPresenterComponent } from "./agente/tipificacion/tipifcacionPresenter.component";
import { TipificacionComponent } from "./agente/tipificacion/tipificacion.component";
import { ConsolaComponent } from "./admin/consola-ext/consola-ext.component";
import { GruposComponent } from "./admin/grupos/grupos.component";
import { CallLogComponent } from "./admin/reportes/call_log.component";
import { AgenteEdicionComponent } from "./admin/agente/agente.component";
import { QueueLogComponent } from "./admin/reportes/queue_log.component";
import { AgentLogComponent } from "./admin/reportes/agent_log.component";
import { CallcenterStatsComponent } from "./admin/reportes/callcenter.component";
import { DialerComponent } from "./admin/dialer/dialer.component";
import { PollsComponent } from "./admin/polls/polls.component";
import { SmsComponent } from "./admin/sms/sms.component";
import { ApiManagerComponent } from "./admin/apimanager/apimanager.component";
import { IvrComponent } from "./admin/ivr/ivr.component";
import { TroncalSetupComponent } from "./admin/troncal/troncal.component";
import { UsuarioComponent } from "./admin/usuarios/usuarios.component";
import { SistemaComponent } from "./admin/sistema/sistema.component";
import { QueueComponent } from "./admin/consola_call/queue/queue.component";
import { CallcenterDashComponent } from "./admin/consola_call/consola_call.component";
import { QueueSetupComponent } from "./admin/consola_call/queuesetup/queuesetup.component";
import { QueueVisualizeGuard } from "./admin/consola_call/queue-visualize.guard";
import { AdminGuard } from "./admin/admin.guard";
import { DirectorioComponent } from "./admin/directorio/directorio.component";
import { DirectorioConfigComponent } from "./admin/directorio/directorio-config/directorio-config.component";
import { DialerSetupComponent } from "./admin/dialer/dialer-setup/dialer-setup.component";
import { CampainViewComponent } from "./admin/dialer/campain-view/campain-view.component";
import { ResultadosTipificacionComponent } from "./admin/tipificacion/resultados-tipificacion/resultados-tipificacion.component";
import { TipificacionesComponent } from "./admin/tipificacion/tipificaciones.component";
import { QueueCallbackLogComponent } from "./admin/reportes/queue-callback-log/queue-callback-log.component";

@NgModule({
   providers: [
      HeaderService,
      UsuarioService,
      AgentResolver,
      FormasService,
      QueueVisualizeGuard,
   ],
   imports: [
      BrowserModule,
      HttpClientModule,
      HttpClientJsonpModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule.forRoot(
         [
            {
               path: "",
               component: LoginComponent,
            },
            {
               path: "login",
               component: LoginComponent,
            },
            {
               path: "dashboard",
               component: AdminComponent,
               canActivate: [AdminGuard],
               children: [
                  {
                     path: "",
                     component: ConsolaComponent,
                     outlet: "view",
                  },
                  {
                     path: "groups",
                     component: GruposComponent,
                     outlet: "view",
                  },
                  {
                     path: "call-log",
                     component: CallLogComponent,
                     outlet: "view",
                  },
                  {
                     path: "agents",
                     component: AgenteEdicionComponent,
                     outlet: "view",
                  },
                  {
                     path: "callcenter",
                     outlet: "view",
                     children: [
                        {
                           path: "",
                           component: CallcenterDashComponent,
                        },
                        {
                           path: ":queueid",
                           component: QueueComponent,
                           canActivate: [QueueVisualizeGuard],
                        },
                        {
                           path: "setup/:queueid",
                           component: QueueSetupComponent,
                           canActivate: [QueueVisualizeGuard],
                        },
                     ],
                  },
                  {
                     path: "callcenter-log",
                     component: QueueLogComponent,
                     outlet: "view",
                  },
                  {
                     path: "agents-report",
                     component: AgentLogComponent,
                     outlet: "view",
                  },
                  {
                     path: "callcenter-report",
                     component: CallcenterStatsComponent,
                     outlet: "view",
                  },
                  {
                     path: "queue-callback-log",
                     component: QueueCallbackLogComponent,
                     outlet: "view",
                  },
                  {
                     path: "call-typing",
                     outlet: "view",
                     children: [
                        {
                           path: "",
                           component: TipificacionesComponent,
                        },
                        {
                           path: ":idformulario",
                           component: ResultadosTipificacionComponent,
                        },
                     ],
                  },
                  {
                     path: "dialer",
                     outlet: "view",
                     children: [
                        {
                           path: "",
                           component: DialerComponent,
                        },
                        {
                           path: "setup/:idcampana",
                           component: DialerSetupComponent,
                        },
                        {
                           path: "campain/:idcampana",
                           component: CampainViewComponent,
                        },
                     ],
                  },
                  {
                     path: "address-book",
                     outlet: "view",
                     children: [
                        {
                           path: "",
                           component: DirectorioComponent,
                        },
                        {
                           path: ":addressbookid",
                           component: DirectorioConfigComponent,
                        },
                     ],
                  },
                  {
                     path: "polls",
                     component: PollsComponent,
                     outlet: "view",
                  },
                  {
                     path: "click2call",
                     component: CallbackComponent,
                     outlet: "view",
                  },
                  {
                     path: "sms",
                     component: SmsComponent,
                     outlet: "view",
                  },
                  {
                     path: "apimanager",
                     component: ApiManagerComponent,
                     outlet: "view",
                  },
                  {
                     path: "ivr",
                     component: IvrComponent,
                     outlet: "view",
                  },
                  {
                     path: "trunks",
                     component: TroncalSetupComponent,
                     outlet: "view",
                  },
                  {
                     path: "users",
                     component: UsuarioComponent,
                     outlet: "view",
                  },
                  {
                     path: "system",
                     component: SistemaComponent,
                     outlet: "view",
                  },
               ],
            },
            {
               path: "agente",
               component: AgenteComponent,
               resolve: {
                  agente: AgentResolver,
               },
               children: [
                  {
                     path: "",
                     outlet: "view",
                     component: TipificacionPresenterComponent,
                  },
                  {
                     path: "tipificar/:idformulario",
                     outlet: "view",
                     component: TipificacionComponent,
                     resolve: {
                        agente: AgentResolver,
                     },
                  },
                  {
                     path: "tipificar/:idformulario/:titulo",
                     outlet: "view",
                     component: TipificacionComponent,
                     resolve: {
                        agente: AgentResolver,
                     },
                  },
               ],
            },
         ],
         { relativeLinkResolution: "legacy" }
      ),
      LocalStorageModule.forRoot({
         prefix: "kerberusipbx_query",
         storageType: "sessionStorage",
      }),
      AdminModule,
      ReportesModule,
      AgenteConsoleModule,
      IvrModule,
   ],
   declarations: [WaitLoaderComponent, IPBXComponent, LoginComponent],
   bootstrap: [IPBXComponent],
})
export class AppModule {
   constructor() {
      moment.locale("es");
      dayjs.locale("es");
   }
}
