<ng-container *ngIf="viewSwitch$ | async; else presenter">
   <agente-tipificacion [agente]="agente" [channel]="channelSnapshot" [formulario]="formularioSeleccionado"
      (doneWithForm)="clearPresenter()">
   </agente-tipificacion>
</ng-container>

<ng-template #presenter>
   <div class="text-muted panel panel-default">
      <div class="panel-heading">
         * Seleccione uno de los formularios.
      </div>
      <div class="row panel-body" style="padding: 10px;" *ngIf="formularios$ | async as formularios; else loading">
         <div class="col-md-6" style="" *ngFor="let forma of formularios">
            <div style="border: 1px solid #e2e2e2; margin-bottom: 10px">
               <div class="text-justify" style="background-color: #f2f2f2; padding: 15px">
                  <span style="font-weight: 600"><i class="fa fa-sticky-note-o" style="color: #cc00cc"></i>
                     {{forma.titulo | txtLimit:45}}</span><br>
                  <span class="text-muted" style="font-size: 12px">{{(forma?.descripcion || 'Sin descripción') |
                     txtLimit:50}}</span>
               </div>

               <div class="text-center" style="padding: 20px">
                  <button type="button" class="btn-sm btn btn-warning" (click)="cargarFormulario(forma)">
                     <i class="fa fa-file-text-o"></i> Usar formulario</button>
               </div>
            </div>
         </div>
      </div>
      <ng-template #loading>
         <div class="text-center text-muted" style="margin-top: 40px; margin-bottom: 37px;">
            <i class="fa fa-2x fa-spinner fa-spin text-primary" style="margin-bottom: 10px;"></i><br>Cargando
            formularios...
         </div>
      </ng-template>
   </div>
</ng-template>