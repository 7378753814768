import { throwError, Observable, timer } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HeaderService } from "./header.service";

import {
   IForma,
   IFormaPregunta,
   ITipificacionLog,
   IResultadosQuery,
   IResultado,
} from "../_interfaces/forma";
import { IDBResponse } from "../_interfaces/responses";
import { map, retryWhen, flatMap, tap, catchError } from "rxjs/operators";
import { IChannel } from "../_interfaces/_all";

@Injectable()
export class FormasService {
   llamadaSnapshot: IChannel;
   constructor(private $http: HttpClient, private $header: HeaderService) {}

   getFormularios(): Observable<IForma[]> {
      return this.$http.get<IForma[]>(this.$header.getAPIurl() + "/formas", {
         headers: this.$header.getHeaders(),
      });
   }

   getPreguntasFormulario(id: string): Observable<IFormaPregunta[]> {
      return this.$http.get<IFormaPregunta[]>(
         this.$header.getAPIurl() + "/forma/" + id,
         { headers: this.$header.getHeaders() }
      );
   }

   guardarFormulario(data: {
      update: boolean;
      frm: IForma;
   }): Observable<IDBResponse> {
      let http$: Observable<IDBResponse>;

      if (data.update === undefined) {
         // Eliminar
         http$ = this.$http.delete<IDBResponse>(
            this.$header.getAPIurl() + "/forma/" + data.frm.id,
            { headers: this.$header.getHeaders() }
         );
      } else if (!!data.update) {
         // Actualizar
         http$ = this.$http.post<IDBResponse>(
            this.$header.getAPIurl() + "/forma",
            data.frm,
            { headers: this.$header.getHeaders() }
         );
      } else {
         // guardar
         http$ = this.$http.put<IDBResponse>(
            this.$header.getAPIurl() + "/forma",
            data.frm,
            { headers: this.$header.getHeaders() }
         );
      }

      return http$;
   }

   guardarLog(forma: ITipificacionLog[]): Observable<IDBResponse> {
      return this.$http
         .put<IDBResponse>(this.$header.getAPIurl() + "/forma_log", forma, {
            headers: this.$header.getHeaders(),
         })
         .pipe(
            retryWhen((err) =>
               err.pipe(
                  tap((err) => console.error("Error registrado", err)),
                  flatMap((err, index) =>
                     index < 4 ? timer((index + 1) * 1000) : throwError(err)
                  )
               )
            )
         );
   }

   guardarResultado(resultado: Object) {
      return this.$http
         .put<IDBResponse>(
            this.$header.getAPIurl() + "/forma_log.v2",
            resultado,
            { headers: this.$header.getHeaders() }
         )
         .pipe(
            retryWhen((err) =>
               err.pipe(
                  // tap(err => console.error('Error registrado', err)),
                  flatMap((err, index) =>
                     index < 3 ? timer((index + 1) * 1000) : throwError(err)
                  )
               )
            ),
            catchError((err) => {
               console.error(err);
               throw new Error(err);
            })
         );
   }

   buscar(cuerpoBusqueda: {
      fecha: { inicio: string; fin?: string };
      idformulario: string;
   }) {
      return this.$http
         .post(
            this.$header.getAPIurl() + "/tipificacion/resultados.v2",
            cuerpoBusqueda,
            { headers: this.$header.getHeaders(), responseType: "text" }
         )
         .pipe(
            map(
               (res) =>
                  JSON.parse(`[${res.substring(1)}]`) as ITipificacionLog[]
            )
         );
   }

   buscarResultados(query: IResultadosQuery): Observable<IResultado[]> {
      return this.$http
         .post(this.$header.getAPIurl() + "/resultados_forma_log", query, {
            headers: this.$header.getHeaders(),
            responseType: "text",
         })
         .pipe(
            map((res) => JSON.parse(`[${res.substring(1)}]`) as IResultado[])
         );
   }
}
