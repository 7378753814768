<div class="row">
    <div class="col-md-3 text-muted">
        <div class="bs-callout bs-callout-success">
            <h4 class="text-success"><i class="fa fa-address-book-o"></i> Directorios de Contacto.</h4>
            Configuración de bases de datos de contactos.
            
            <button
            (click)="crearNuevoDirectorio()"
            type="button" class="btn btn-success" style="margin-top: 15px;">
                <i class="fa fa-plus-circle"></i> Crear nuevo directorio
            </button>
            
        </div>
        <div class="panel panel-primary">
            <div class="panel-heading">
                <i class="fa fa-address-book-o"></i> Directorios disponibles
            </div>
            <ng-container *ngIf="directorios$ | async as directorios; else crear">
                <ng-container *ngIf="directorios.length > 0; else noDirectorios">
                    <ul class="list-group">
                        <li class="list-group-item" *ngFor="let directorio of directorios">
                            <a href="javascript:void(0)" (click)="seleccionarDirectorio(directorio)">
                                <i class="fa fa-address-card-o"></i> {{directorio.nombre}}
                            </a>
                        </li>
                    </ul>
                </ng-container>
                <ng-template #noDirectorios>
                    <div class="panel-body text-center">
                        Aun no tienes directorios disponibles.
                    </div>
                </ng-template>
            </ng-container>
            <ng-template #crear>
                <div class="panel-body text-center text-primary">
                    <i class="fa fa-spinner fa-spin" style="margin-bottom: 10px;"></i><br>
                    Cargando directorios...
                </div>
            </ng-template>
        </div>
    </div>
    <div class="col-md-9">
        <app-contactos [directorio]="directorio" (reloadView)="ngOnInit()"></app-contactos>
    </div>
</div>