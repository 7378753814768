<ng-container *ngIf="resultados | preguntas | async as enunciados">
<ng-container *ngIf="resultados | camposContacto | async as cabecerasContacto">
<div class="table-responsive" style="margin-top: 20px;">
    <table class="table table-striped table-bordered" style="font-size: 13px;">
        <thead style="color: white; background-color: rgb(54, 139, 214);">
            <tr>
                <th colspan="6" class="text-center th">Información del evento</th>
                <th *ngIf="cabecerasContacto.length > 0" [attr.colspan]="cabecerasContacto.length" class="text-center th">
                    <i class="fa fa-address-card-o" style="margin-right: 5px;"></i> Información Contacto</th>
                <th *ngIf="enunciados.length > 0" [attr.colspan]="enunciados.length" class="text-center th">
                    <i class="fa fa-wpforms" style="margin-right: 5px;"></i> Resultado Tipificación</th>
            </tr>
            <tr>
                <th class="text-center th" style="max-width: 60px"><i class="fa fa-phone text-info"></i></th>
                <th class="text-center th">Fecha</th>
                <th class="text-center th">ID Autor</th>
                <th class="text-center th">Nombre del autor</th>
                <th class="text-center th">Número</th>
                <th class="text-center th">Campaña de Callcenter</th>
                <th class="text-center th" *ngFor="let cabecera of cabecerasContacto">
                     {{cabecera}}</th>
                <th class="text-center th" *ngFor="let enunciado of enunciados">
                    {{enunciado}}</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="!!resultados && resultados.length > 0; else noRows">
                <tr *ngFor="let resultado of resultados | paginate: config; let i = index">
                    <td class="text-center"><i class="fa fa-phone-square"
                        [ngClass]="{'text-danger': resultado.callid | inCall, 'text-success' : !(resultado.callid | inCall)}"></i>
                    </td>
                    <td class="text-center">{{resultado?.fecha}}</td>
                    <td class="text-center">{{resultado?.idautor}}</td>
                    <td style="overflow: hidden; white-space: nowrap">
                        <div>{{resultado?.autor}}</div></td>
                    <td class="text-center">{{resultado?.numero}}</td>
                    <td>{{resultado?.cola}}</td>
                    <ng-container *ngFor="let cabecera of cabecerasContacto">
                        <td style="background-color: rgb(237, 244, 252);">{{resultado?.contacto[cabecera] || 'N/A'}}</td>
                    </ng-container>
                    <ng-container>
                        <td *ngFor="let enunciado of enunciados">
                            {{resultado.preguntas | respuestas: enunciado}}
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
            <ng-template #noRows>
                <td colspan="8">
                    <div class="text-center" style="margin: 60px 0;">
                        <i class="fa fa-table fa-4x" style="color: rgb(160, 160, 160);"></i>
                        <h5 class="text-muted">No hay registros para mostrar.</h5>
                    </div>
                </td>
            </ng-template>
        </tbody>
    </table>
    <div class="text-muted small">
        <h5>Total resultados: {{resultados?.length || 0}}</h5>
        <i class="fa fa-phone-square text-success"></i> Tipificación con llamada.
        <i class="fa fa-phone-square text-danger" style="margin-left: 10px;"></i> Tipificación <strong>sin</strong> llamada.
    </div>
</div>
<hr>
<div style="float: left; margin-top: 10px;">
    <a href="javascript: void(0)" (click)="regresar()" >
        <i class="fa fa-arrow-circle-o-left"></i> Regresar
    </a>
</div>
<div class="text-right">
    <pagination-template #p="paginationApi"
        [id]="config.id"
        (pageChange)="config.currentPage = $event">

        <div class="btn-group">
            <button type="button" class="btn btn-default" 
                [disabled]="p.isFirstPage()"
                (click)="p.previous()">
                <i class="fa fa-arrow-circle-o-left"></i>
            </button>

            <button type="button" class="btn btn-default" *ngFor="let page of p.pages"
                [disabled]="p.getCurrent() === page.value"
                [ngClass]="{'active': p.getCurrent() === page.value}"
                (click)="p.setCurrent(page.value)">
                {{ page.label }}
            </button>
            
            <button type="button" class="btn btn-default" 
                [disabled]="p.isLastPage()"
                (click)="p.next()">
                <i class="fa fa-arrow-circle-o-right"></i>
            </button>
        </div>&nbsp;
        <button type="button" class="btn btn-warning" 
            (click)="descargar(enunciados, cabecerasContacto)"
            [disabled]="!resultados || resultados.length === 0"><i class="fa fa-cloud-download"></i> Descargar en *.csv</button>
    </pagination-template>
</div>

</ng-container>
</ng-container>