<div *ngIf="agente$ | async as agente; else loading">
   <nav class="navbar navbar-inverse navbar-static-top" style="margin-bottom: 0px;">
      <div class="container-nav">
         <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar"
               aria-expanded="false" aria-controls="navbar">
               <span class="sr-only">Toggle navigation</span>
               <span class="icon-bar"></span>
               <span class="icon-bar"></span>
               <span class="icon-bar"></span>
            </button>
            <span class="navbar-brand">
               <span style="color: white">
                  <img src="public/img/logoKerberus_.png" title="Expandir Menu">
               </span>
            </span>
         </div>
         <div id="navbar" class="navbar-collapse collapse" *ngIf="operacionEncurso$ | async as operacion">
            <ul class="nav navbar-nav">
               <li style="padding-top: 10px">

                  <app-agent-controller [agente]="agente" [callStatus]="callStatus"
                     [disabled]="operacion.inProgress || !agente.canChangeSkill" (contacto)="cargarContacto($event)"
                     (directorio)="cargarFormularioContacto($event)" (cambioSkill)="cambiarModo($event, agente)">
                  </app-agent-controller>

               </li>
               <li class="li-stat">
                  TMO General
                  <span class="badge">
                     {{agente.states | totalAttendedCalculator | date: 'HH:mm:ss'}}
                  </span>
               </li>
            </ul>
            <ul class="nav navbar-nav navbar-right" style="padding-top: 10px">
               <li>
                  <button type="button" class="btn btn-default" (click)="cerrarSesion()"><i class="fa fa-sign-out"></i>
                     Salir</button>
               </li>
            </ul>
            <ul class="nav navbar-nav navbar-right" style="padding-top: 10px; margin-right: 10px">
               <li>
                  <app-schedule [agente]="agente" (accion)="ejecutarAccionAgenda($event, agente.estado)">
                  </app-schedule>
               </li>
            </ul>
            <ul class="nav navbar-nav navbar-right" style="padding-top: 10px; margin-right: 10px">
               <li>
                  <div class="btn-group" role="group" aria-label="..." *ngIf="!!agente">

                     <div class="btn-group" role="group" (click)="cargarMotivosPausa()"
                        *ngIf="!agente?.estado?.includes('PAUSE')">
                        <button type="button" class="btn btn-warning dropdown-toggle"
                           [disabled]="agente.estado === 'OFFLINE' || !agente.canPause || operacion.inProgress"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           <i class="fa fa-pause" style="margin-right: 5px;"></i> Pausa <i class="fa fa-caret-down"></i>
                        </button>
                        <ul class="dropdown-menu">
                           <ng-container *ngIf="pauseReason$ | async as pauseReason; else loadingReasons">
                              <li *ngFor="let motivo of pauseReason">
                                 <a (click)="Pausa(agente, motivo.reason, 'pause')">{{motivo.reason}}</a>
                              </li>
                           </ng-container>
                           <ng-template #loadingReasons>
                              <li class="text-center">
                                 <i class="fa fa-spinner fa-spin text-primary" style="margin: 10px 0;"></i>
                              </li>
                           </ng-template>
                        </ul>
                     </div>

                     <!-- Pause controls  -->
                     <ng-container *ngIf="pauseStateTime$ | async as pauseTime">
                        <button type="button" class="btn btn-warning" (click)="Pausa(agente, 'Regresa', 'unpause')"
                           *ngIf="agente?.estado?.includes('PAUSE')" [disabled]="operacion.inProgress">
                           <i class="fa fa-play"></i> {{pauseTime | date:'HH:mm:ss' }}
                        </button>
                     </ng-container>

                     <button type="button"
                        [disabled]="!agente?.estado?.includes('BUSY') || !agente.canHangup || operacion.inProgress"
                        class="btn btn-danger" (click)="colgarLlamada(agente,'')" title="Colgar llamada">
                        <span class="glyphicon glyphicon-phone-alt"></span>
                     </button>
                  </div>
               </li>
            </ul>
         </div>
         <!--/.nav-collapse -->
      </div>
   </nav>
   <!-- Page Content -->

   <!-- new version -->
   <div style="margin:10px;">
      <div class="row" style="background-color: rgb(245, 245, 245); margin: 0; padding: 0; border-radius: 7px 0 0 7px;">
         <div class="col-lg-2 hidden-xs hidden-sm hidden-md" style="padding-right: 0;">
            <div style="font-size: 16px; margin-top: 15px; margin-bottom: 10px;">
               <i class="fa fa-history"></i> Interacciones<br>
               <small class="text-right text-muted" style="margin-top: 0px; font-weight: 500;"
                  *ngIf="currentTime$ | async as fechaActual">
                  {{fechaActual | toDate | amDateFormat: 'dddd D [de] MMMM, h:mm a'}}
               </small>
            </div>

            <div class="panel panel-shadow" style="background-color: rgb(204, 0, 0);" [@inOutAnimation]
               *ngIf="agente?.estado?.includes('BUSY') && channelSnapshot$ | async as channel; else noLead">
               <div class="panel-body text-white" style="font-size: 14px; padding: 8px; padding-top: 4px;">
                  <div class="row">
                     <div class="col-xs-8">
                        <small>{{channel.callerid.name}}</small><br>
                        {{channel?.callerid.number}}<br>
                        <small>Q: {{channel?.queue_name}}</small>
                     </div>
                     <div class="col-xs-4 text-right" style="padding-top: 10px; padding-right: 25px;">
                        <div class="text-center phone-icon">
                           <i class="fa fa-2x fa-phone" style="margin-top: 7px; color: rgb(204, 0, 0);"></i>
                        </div>
                     </div>
                  </div>
                  <div class="row" style="margin-top: 10px;">
                     <div class="col-xs-4">
                        <span class="label label-info" style="font-size: 12px;"
                           *ngIf="incallStateTime$ | async as incallTime">
                           {{incallTime | date:'HH:mm:ss'}}
                        </span>
                     </div>
                     <div class="col-xs-8 text-right">
                        <small>
                           {{(channel?.queue_start_time * 1000) | toDate | amDateFormat: 'D [de] MMMM, HH:mm'}}
                        </small>
                     </div>
                  </div>
               </div>
            </div>
            <ng-template #noLead>
               <div class=" text-center text-muted" style="margin-top: 30px;">
                  <small>No hay interacciones aun.</small>
               </div>
            </ng-template>
         </div>
         <div class="col-lg-5 col-md-6">

            <!-- Nav tabs -->
            <ul class="nav nav-tabs" style="margin-top: 10px;" id="tabContacto" name="tabContacto" role="tablist">
               <li role="presentation" class="active">
                  <a href="#Marcador" aria-controls="Marcador" role="tab" data-toggle="tab">
                     <i class="fa fa-tty"></i>
                     Marcadores</a>
               </li>
               <li role="presentation">
                  <a href="#Contacto" aria-controls="Contacto" role="tab" data-toggle="tab">
                     <i class="fa fa-address-card-o"></i> Datos de Contacto
                  </a>
               </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content tab-style">
               <div role="tabpanel" class="tab-pane fade in active" id="Marcador">
                  <app-marcador [agente]="agente" [callStatus]="callStatus" (contacto)="cargarContacto($event)">
                  </app-marcador>
               </div>
               <div role="tabpanel" class="tab-pane fade" id="Contacto">
                  <app-contacto [contacto$]="idContacto$.asObservable()" [directorio$]="directorio$.asObservable()"
                     (updateChannel)="actualizaCanalAgente($event)"></app-contacto>
               </div>
            </div>
            <!-- Tab panes -->

            <hr>
            <div style="font-size: 16px; margin-top: 15px;">
               <i class="fa fa-bar-chart-o"></i> Estadísticas de atención
            </div>
            <small class="text-muted">Información de rendimiento del agente</small>
            <table class="table table-striped table-bordered " style="font-size: 12px; margin-top: 10px;">
               <thead style="background-color: #ebebeb">
                  <tr>
                     <th colspan="2">Cola</th>
                     <th class="text-center">Skill</th>
                     <th class="text-center" colspan="2">Inbound</th>
                     <th class="text-center" colspan="2">Outbound</th>
                     <th class="text-center" colspan="2">Dialer</th>
                  </tr>
               </thead>
               <tbody>
                  <tr *ngFor="let stat of agente.states">
                     <td>{{stat.queuename}}</td>
                     <td>
                        <i class="fa fa-star" style="color: red; margin-right: 5px;"
                           *ngIf="stat.bound_channel_id !== ''"></i>
                        <span class="label" [ngClass]="'label-' + (stat.status | statusColor)">{{stat?.status |
                           statusName}}</span>
                     </td>
                     <td class="text-center">{{stat.penalty | statsSkillsNames}}</td>
                     <td class="text-center">{{stat.inbound}}</td>
                     <td class="text-center">{{stat.tmo_inbound | tmoCalculator: stat.inbound |
                        date:'HH:mm:ss'}}
                     </td>
                     <td class="text-center">{{stat.outbound}}</td>
                     <td class="text-center">{{stat.tmo_outbound | tmoCalculator: stat.outbound |
                        date:'HH:mm:ss'}}</td>
                     <td class="text-center">{{stat.dialer}}</td>
                     <td class="text-center">{{stat.tmo_dialer | tmoCalculator: stat.dialer | date:'HH:mm:ss'}}
                     </td>
                  </tr>
               </tbody>
            </table>


         </div>
         <div class="col-lg-5 col-md-6" style="padding: 0; background-color: rgb(209, 209, 209)">

            <div class="row interaction-menu">
               <div class="col-xs-6 text-center" style="line-height: 16px;">
                  <a href="javascript:void(0)" (click)="setInteractionView(enumInteraction.METAINFO)"
                     [ngClass]="{'unSelect' : interactionWindow !== enumInteraction.METAINFO}">
                     <i class="fa fa-compass"></i>
                     Meta-Info
                  </a><br>
                  <div class="tab-marker" [@inOutAnimation] *ngIf="interactionWindow === enumInteraction.METAINFO">
                  </div>
               </div>
               <div class="col-xs-6 text-center" style="line-height: 16px;">
                  <a href="javascript:void(0)" (click)="setInteractionView(enumInteraction.TIPIFICACION)"
                     [ngClass]="{'unSelect' : interactionWindow !== enumInteraction.TIPIFICACION}">
                     <i class="fa fa-keyboard-o"></i>
                     Tipificación
                  </a><br>
                  <div class="tab-marker" [@inOutAnimation] *ngIf="interactionWindow === enumInteraction.TIPIFICACION">
                  </div>
               </div>
            </div>

            <div class="interaction-space">
               <tipificacion-presenter *ngIf="interactionWindow === enumInteraction.TIPIFICACION" [agente]="agente"
                  [channelSnapshot]="channelSnapshot$ | async">
               </tipificacion-presenter>
               <div *ngIf="interactionWindow === enumInteraction.METAINFO">

                  <div style="color: #e9e9e9; margin-top: 10px; padding: 25px;">
                     Sin Información adicional.
                  </div>

               </div>
               <div *ngIf="interactionWindow === enumInteraction.TEXT"></div>
            </div>

         </div>
      </div>
   </div>

   <div class="text-center">
      <div style="color: #a6afc1; font-size: 12px">
         &copy; 2006 - {{dateInView | date : 'YYYY'}} Kerberus Ingeniería S.A.S
      </div>
   </div>

   <div class="modal fade" tabindex="-1" role="dialog" id="xferConfirm">
      <div class="modal-dialog modal-sm" role="document">
         <div class="modal-content">
            <div class="modal-body text-center">
               Desea transferir esta llamada a <br><strong> XXX ?</strong>
            </div>
            <div class="modal-footer">
               <button class="btn btn-sm btn-default" data-dismiss="modal">Cancelar</button>
               <button class="btn btn-sm btn-primary" (click)="ejecutarXfer(agente)">Aceptar</button>
            </div>
         </div>
      </div>
   </div>

   <div class="modal fade" tabindex="-1" role="dialog" id="modalWait_kerberus">
      <div class="modal-dialog modal-sm" role="document">
         <div class="modal-content">
            <div class="modal-body text-center">
               <img src="public/img/ajax-loader.gif" alt="Un momento..."><br>
               Un momento por favor...
            </div>
         </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
   </div><!-- /.modal -->

   <div class="modal fade" tabindex="-1" role="dialog" id="modalWait_kerberus_call">
      <div class="modal-dialog modal-sm" role="document">
         <div class="modal-content">
            <div class="modal-body text-center">
               <img src="public/img/ajax-loader.gif" alt="Un momento..."><br>
               Ejecutando la llamada...
            </div>
         </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
   </div><!-- /.modal -->
</div>
<ng-template #loading>
   <div class="panel panel-body text-center text-muted" style="margin: 15px; padding: 120px 0;">
      <ng-container *ngIf="noAgent$ | async; else waiting">
         <i class="fa fa-4x fa-plug" style="color: rgb(206, 206, 206); margin-bottom: 10px;"></i>
         <h5>Parece que este agente no esta en ninguna campaña de callcenter.</h5>
         <small>Verifica con el supervisor o manager de callcenter. <strong><a href="javascript:void(0)"
                  (click)="cerrarSesion()">Regresar</a></strong></small>
      </ng-container>
      <ng-template #waiting>
         <i class="fa fa-2x fa-spinner fa-spin text-primary"></i><br>
         <h5>Cargando datos del agente...</h5>
      </ng-template>
   </div>
</ng-template>