<form novalidate class="form-group form-group-sm" style="margin-bottom: 0" [formGroup]="formTroncal">
    <div class="row">
        <div class="col-md-3">
            
            <div class="bs-callout bs-callout-primary">
                <h4 class="text-primary"><i class="fa fa-plug"></i>  Troncales Disponibles</h4>
                Aquí podrás configurar nuevas troncales o editarlas a demás de establecer contextos.
                <button type="button" style="margin-top: 10px" class="btn btn-sm btn-primary" (click)="nuevaTroncal()"><i class="fa fa-plus"></i> Agregar nueva troncal</button>
            </div>
            <div class="list-group">
                <a href="javascript:void(0)" class="list-group-item" 
                   *ngFor="let t of (troncal$ | async)" 
                   [ngClass]="{'active': !!troncal && t.nombre === troncal.nombre}"
                   (click)="seleccionarTroncal(t)">
                   <i class="fa fa-signal"></i> {{t.nombre}}
                </a>
            </div>
        </div>    
        <div class="col-md-3">
            <div class="bs-callout bs-callout-success">
                <h5>Configuración General de troncal</h5>
                Configuración de parametros.
            </div>
            <div style="margin-bottom: 10px">
                <label>Protocolo </label>
                <select class="form-control" formControlName="protocolo">
                    <option value="">Selecciona uno</option>
                    <option value="SIP">SIP</option>
                    <option value="IAX2">IAX2</option>
                    <option value="DAHDI">Análogo</option>
                </select>
                <small class="text-danger" *ngIf="f.protocolo.errors && f.protocolo.errors.required && (f.protocolo.dirty || f.protocolo.touched)">
                    * Es necesario establecer un protocolo.
                </small>
            </div>
            <div style="margin-bottom: 10px">
                <label>Nombre de Troncal</label>
                <input class="form-control" 
                       formControlName="nombre" 
                       placeholder="nombre para ésta troncal">
                <small class="text-danger" style="font-weight: 400px;" 
                    *ngIf="f.nombre.errors && (f.nombre.dirty || f.nombre.touched)">
                    <div *ngIf="f.nombre.errors.required">
                        * El nombre de la troncal es requerido.
                    </div>
                    <div *ngIf="f.nombre.errors.startsWithLettersError">
                        * El nombre de la troncal debe comenzar con letras.
                    </div>
                </small>
            </div>
            <div class="row">
                <div class="col-md-6" style="margin-bottom: 10px">
                    <label>Host</label>
                    <input class="form-control" formControlName="host" placeholder="dynamic, IP ó DNS">
                </div>
                <div class="col-md-6" style="margin-bottom: 10px">
                    <label>Tipo</label>
                    <select class="form-control" formControlName="type" #type>
                        <option value="">Selecciona uno</option>
                        <option value="PEER">PEER</option>
                        <option value="FRIEND">FRIEND</option>
                    </select>
                </div>
                <div class="col-md-12" *ngIf="f.host.errors || f.type.errors" style="margin-bottom: 10px;">
                    <div class="text-danger" *ngIf="f.host.errors && f.host.errors.required && (f.host.dirty || f.host.touched)">
                        <small>* Introduce una dirección IP o dns.</small>
                    </div>
                    <div class="text-danger" *ngIf="f.type.errors && f.type.errors.required && (f.type.dirty || f.type.touched)">
                        <small>* Debes seleccionar un tipo de conexión. Tip: <strong>FRIEND</strong> si confias en la fuente.
                        </small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div style="margin-bottom: 10px" class="col-md-6">
                    <label>DTMF</label>
                    <select class="form-control" formControlName="dtmf">
                        <option value="rfc2833">RFC2833</option>
                        <option value="inband">Inband</option>
                        <option value="info">Info</option>
                    </select>
                </div>
                <div style="margin-bottom: 10px"  class="col-md-6">
                    <label>NAT</label>
                    <select class="form-control" formControlName="nat">
                        <option value="auto_comedia">Auto Comedia</option>
                        <option value="comedia">Comedia</option>
                        <option value="auto_force_rport">Auto Force RPORT</option>
                        <option value="force_rport">Force RPORT</option>
                        <option value="no">No</option>
                    </select>
                </div>
                <div class="col-md-12" *ngIf="f.dtmf.errors || f.nat.errors" style="margin-bottom: 10px;">
                    <div class="text-danger" *ngIf="f.dtmf.errors.required && (f.dtmf.dirty || f.dtmf.touched)">
                        <small>* Debes seleccionar un método de DTMF, ej: <strong>RFC2833</strong>
                        </small>
                    </div>
                    <div class="text-danger" *ngIf="f.nat.errors.required && (f.nat.dirty || f.nat.touched)">
                        <small>* <strong>Selecciona un método de NAT, ej: </strong>Auto Comedia</small>
                    </div>
                </div>
            </div>
            <div style="margin-bottom: 10px">
                <label>RTP's</label>
                <input class="form-control" formControlName="rtp" placeholder="Direcciones RTP / Mascara de red">
                <small class="text-muted">* Opcional - Puedes agregar multiples sepradas por cómas.</small>
            </div>
        </div>
        <div class="col-md-3">
            <div class="bs-callout bs-callout-success">
                <h5>Autenticación</h5>
                Información para registro de la troncal.
            </div>
            <div style="margin-bottom: 10px">
                <label>Usuario</label>
                <input class="form-control" formControlName="usuario" placeholder="Usuario...">
                <small class="text-muted">* Opcional</small>
            </div>
            <div style="margin-bottom: 10px">
                <label>Contraseña</label>
                <div class="input-group">
                    <input type="password" class="form-control" *ngIf="!secretView" formControlName="password" >
                    <input type="text" class="form-control" *ngIf="secretView" formControlName="password" >
                    <span class="input-group-btn">
                        <button class="btn btn-sm btn-default" type="button" (mousedown)="secretView=true" (mouseup)="secretView=false">
                            <span class="glyphicon glyphicon-eye-open"></span>
                        </button>
                    </span>
                </div><!-- /input-group -->
                <small class="text-muted">* Opcional</small>
            </div>
            <div style="margin-bottom: 10px">
                <label>Caller ID</label>
                <input class="form-control" formControlName="callerid" placeholder="Ej: DID de Cabecera...">
                <small class="text-muted">* Opcional - Útil si tu operador autentica con CallerID.</small>
            </div>
            <div class="from-group">
                <label>Dominio de contacto</label>
                <input class="form-control" formControlName="domain" placeholder="Ej: midominio.tl">
                <small class="text-muted">* Opcional - modifica el encabezado de contacto.</small>
            </div>
        </div>
        <div class="col-md-3">
            <div class="bs-callout bs-callout-success">
                <h5>Codecs</h5>
                Codec soportados para la troncal.
            </div>
            <div class="row" style="margin-top: 10px" formArrayName="codec">
                <div class="col-xs-3 text-center" *ngFor="let opcion of codecControlArray.controls; let i = index;">
                    <ng-container [formGroupName]="i">
                        <label>{{opcion.value.nombre}}</label>
                        <ui-toggleV1 [formControl]="opcion.controls['presente']"></ui-toggleV1>
                    </ng-container>
                </div>
            </div>
            
            <div class="bs-callout bs-callout-warning" style="margin-top: 10px">
                <h5>Contextos & Prefijos</h5>
                Contextos en los que puede ser usada.
            </div>
            <table class="table table-striped" style="font-size: 12px; margin-bottom: 10px;">
                <thead style="background-color: #ED951B; color: white; font-weight: 600">
                    <tr>
                        <td class="text-center">Prioridad.</td>
                        <td>Contexto</td>
                        <td colspan="2">Prefijo</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let prefijo of prefijosArray.controls; let i = index;">
                        <ng-container [formGroup]="prefijo">
                            <td>
                                <select class="form-control" formControlName="prioridad">
                                    <option value="">Selecciona Una</option>
                                    <option value="{{p}}" *ngFor="let p of [1,2,3,4,5,6,7,8,9,0]">{{p}}</option>
                                </select>
                            </td>
                            <td width="40%">
                                <select class="form-control" formControlName="contexto">
                                    <option value="local">Local</option>
                                    <option value="nacional">Nacional</option>
                                    <option value="celular">Celular</option>
                                    <option value="internacional">Internacional</option>
                                    <option value="interx">Interconexión</option>
                                </select>
                            </td>
                            <td>
                                <input type="text" class="form-control text-center" formControlName="prefijo" placeholder="Ej: +57">
                            </td>
                            <td class="text-center">
                                <div style="padding-top: 7px;">
                                <a href="javascript:void(0)"><i class="fa fa-trash" (click)="eliminarPrefijo(prefijosArray, i)"></i></a>
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>         
            <button type="button" class="btn btn-sm btn-warning"
                    (click)="agregarPrefijo(prefijosArray)">Agregar prefijo</button>   
        </div>
        <div class="col-md-12 text-right">
            <hr>
            <button type="button" class="btn btn-sm btn-default" [disabled]="!readOnly" (click)="eliminarTroncal(formTroncal.getRawValue())">Eliminar Troncal</button>&nbsp;
            <button type="button" class="btn btn-sm btn-primary" [disabled]="formTroncal.invalid" (click)="guardarTroncal(formTroncal.value);">Guardar Troncal</button>
        </div>
    </div>
</form>
