import { IContacto } from './contacto';

export interface IForma {
    id?: string,
    titulo: string,
    descripcion: string,
    fecha: Date | string,
    colas: string | string[],
    
    pregunta?: IFormaPregunta[]
}

export interface IFormaPregunta {
    orden: number,
    idformulario: string,
    tipo: string,
    pregunta: string,
    respuestas: {
        tipo: string,
        respuesta: string
    }[] | string 
}

export enum ETipoRespuestaTipificacion {
    SIMPLE = 'SIMPLE',
    SELECCION = 'SELECCION',
    FECHA = 'FECHA',
    OTRO = 'OTRO'
}

export interface ITipificacionLog {
    callid: string;
    idtipificacion?: string;
    idformulario: string;
    fecha: Date;
    idautor: string;
    autor: string; // <-- nombre del agente o usuario
    cola?: string;
    numero: string;
    contacto?: IContacto;
    preguntas: {
        tipo: ETipoRespuestaTipificacion;
        enunciado: string;
        respuesta: string;
        respuestas?: []
    }[];
}

export interface IResultado {
    callid: string,
    idtipificacion: string
    fecha: string,
    autor: string,
    tipo: string,
    pregunta: string
    respuesta: string,
    queuename: string,
    src: string,
    modalidad: string
}

export interface IResultadosQuery {
   fecha?: {
        inicio: string, 
        fin: string
    },
   idformulario: string,
   tipoQuery: EQueryType
}

export enum EQueryType {
    LIST, CSV
}
